<template>
  <div>
    <div class="e-breadcrumb">项目分类统计</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">运营报表
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">项目业绩统计明细
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">赠送业绩统计
              </div>
            </div>
          </div>
          <TabOperate  v-if="tabIndex==0"/>
          <TabAchievement  v-if="tabIndex==1"/>
          <TabGive  v-if="tabIndex==2"/>
          <!--<TabIntegral  v-if="tabIndex==1"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabOperate from './operate/entry'
  import TabAchievement from './achievement/entry'
  import TabGive from './give/entry'

  export default {
    data() {
      return {
        tabIndex:0,
        item: {},
      }
    },
    async created() {
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabOperate,
      TabAchievement,
      TabGive,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
