<template>
  <div>
    <div class="order-table-wrapper" style="padding-top:0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <div class="e-tabs-wrapper">
                <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">今天
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==1}" @click="switchTable(1)">昨天
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==2}" @click="switchTable(2)">本月
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==3}" @click="switchTable(3)">上月
                </div>
              </div>
              <span class="v-form-label">门店</span>
              <el-select size="small" clearable  v-model="departmentCode" placeholder="选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" style="margin-left: 28px" type="primary"
                           @click="requestSearch()">
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <el-table
            :data="list"
            :header-cell-style="{background:'#fff','font-weight':'bold'}"
            style="width: 100%;">
            <el-table-column  min-width="50%" prop="departmentName" label="门店" align="center">
            </el-table-column>
            <el-table-column min-width="50%" prop="orderNum" label="服务总客数" align="center">
            </el-table-column>
            <el-table-column min-width="50%" prop="skuTotal" label="卖品总业绩" align="center">
            </el-table-column>
            <el-table-column min-width="50%" prop="packageTotal" label="套餐总业绩" align="center">
            </el-table-column>
            <el-table-column min-width="50%" prop="repayment" label="办卡/充卡业绩" align="center">
            </el-table-column>
            <el-table-column min-width="50%" prop="itemTotal" label="服务项目总业绩" align="center">
            </el-table-column>
            <el-table-column  label="业绩明细" align="center">
              <el-table-column min-width="50%" prop="itemCard" label="项目划卡业绩" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="itemCash" label="项目现金业绩" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="packageCard" label="套餐划卡业绩" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="packageCash" label="套餐现金业绩" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="skuCard" label="商品划卡业绩" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="skuCash" label="商品现金业绩" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="指定客分析" align="center">
              <el-table-column min-width="50%" prop="nvOrderNum" label="女客" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="nanOrderNum" label="男客" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="city" label="指定率" align="center">
              </el-table-column>
              <el-table-column min-width="50%" prop="address" label="同比上月" align="center">
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :pager-count="7"
              layout="total, prev, pager, next,jumper"
              :total="pagination.count"
              :page-count="pagination.pages"
              :current-page="pagination.pageNumber"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        tabIndex: 0,
        storeList: [],
        cxDate: [],
        departmentCode: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
      }
    },
    async created() {
      this.getStoreList()
    },
    methods: {
      switchTable(index) {
        this.tabIndex = index
      },
      async getStoreList() {
        let res = await this.$get("/admin/getStoreList")
        this.storeList = res;
      },


      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      async requestSearch(page) {
        let currentPage = page || 1;
        let staDate = '';
        let endDate = '';
        let dateType = '';
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
          dateType = ''
        } else {
          dateType = this.tabIndex
        }
        let data = {
          dateType: dateType,
          staDate: staDate,
          endDate: endDate,
          departmentCode: this.departmentCode,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getItemForPage", data)
        this.list = res.list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },
    },
  }

</script>

<style lang="scss" scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .box-cards {
    width: 35%;
    margin-right: 20px;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }

  }

  .text-items {
    width: 50%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
    .item-h4 {
      height: 20px;
      font-size: 12px;
    }
  }
</style>
